import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex items-center justify-between my-4"},[_c('div',{staticClass:"flex items-center justify-space-between"},[_c('div',{staticClass:"flex items-center"},[_c('SelfAsIcon',{staticClass:"text-primary-light w-10 mr-1"}),_c('h1',{staticClass:"text-black text-xl lg:text-2xl"},[_vm._v("Self Assessments Due")])],1)])]),_c('div',{staticClass:"c-alert__table editable_table flex flex-col w-full"},[_c(VDataTable,{ref:"filterTable",staticClass:"a-table w-full",attrs:{"single-select":"","item-key":"id","items-per-page":5,"item-class":function () { return 'cursor-pointer'; },"headers":_vm.headers,"items":_vm.stats.selfAssessmentDue.list,"footer-props":_vm.footerProps,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"no-data-text":_vm.emptyText,"no-results-text":_vm.emptyText},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.rowClickHandler},scopedSlots:_vm._u([{key:"item.selfAssessmentDue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.selfAssessmentDue))+" ")]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c(VProgressLinear,{attrs:{"background-color":"#F0F0F0","color":_vm.getProgressColor((item.numCompletedIndicators/item.numTotalIndicators)*100),"value":(item.numCompletedIndicators/item.numTotalIndicators)*100,"rounded":"","height":"6"}})]}},{key:"item.redirect",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right tablet-hide",class:{'active-tr': item.active}},[_c('a',{staticClass:"hover:text-primary mx-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.rowClickHandler(item)}}},[_c('fa-icon',{staticClass:"text-grey-2 ml-3",attrs:{"icon":"chevron-right"}})],1)])]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }