<template>
    <div class="container py-4">
        <div v-if="errorCode === 500"
            class="text-center"
        >
            <h1 class="text-black text-lg lg:text-2xl text-center mb-3 mt-4">Unable to load this page!
            </h1>
            <p>Please try again by refreshing or contact your system administrator.</p>
            <img src="@/assets/images/error.png" class="max-w-500 m-auto" :style="imgStyle" alt="Error" />
        </div>
        <div v-else>
            <div class="flex items-center my-4">
                    <div class="flex-row max-w-200">
                        <div class="text-xs uppercase font-bold mb-2">Date range</div>
                        <v-select
                            :items="dataRangeType"
                            v-model="valueType"
                            hide-details
                            dense="true"
                            class="pt-3"
                            :ripple="false"
                        ></v-select>
                    </div>
                    <div class="flex-row min-w-200 max-w-200 ml-4" v-if="valueType === 2">
                        <div class="text-xs uppercase font-bold mb-2">Custom Range</div>
                        <DateRangePicker
                            dense
                            hide-details
                            :solo="true"
                            label="dd/mm/yyyy - dd/mm/yyyy"
                            class="border-b border-grey-4"
                            :flat="true"
                            :ripple="false"
                            :max-date="null"
                            v-model="dateRange"
                        />
                    </div>
            </div>
            
            <div class="grid grid-cols-2 gap-4" v-if="!loading">
                <OverviewManagement :stats="stats" />
                <UpcomingSurveyVisits :stats="stats"/>
                <SelfAssessmentsDue :stats="stats"/>
                <PracticesRequiringAction :stats="stats"/>
            </div>

            <div class="flex items-center justify-center h-20 w-full" v-else>
                <Loader class="w-12 h-12" />
            </div>
        </div>
    </div>
</template>

<script> 
import { AuthMixin } from "@/components/shared/mixins/authHelpers"
import PracticesIcon from '@/assets/icons/nav/practices.svg'
import DateRangePicker from '@/components/partials/DateRangePicker'
import OverviewManagement from '@/components/management-report/partials/OverviewManagement'
import UpcomingSurveyVisits from '@/components/management-report/partials/UpcomingSurveyVisits' 
import PracticesRequiringAction from '@/components/management-report/partials/PracticesRequiringAction' 
import SelfAssessmentsDue from '@/components/management-report/partials/SelfAssessmentsDue' 
import Loader from '@/assets/loader.svg'
import camelcaseKeys from 'camelcase-keys'
import {format} from 'date-fns'

export default {
    name: 'ManagementReports',
    components: { PracticesIcon, DateRangePicker, OverviewManagement, UpcomingSurveyVisits, PracticesRequiringAction, SelfAssessmentsDue, Loader },
    mixins: [AuthMixin],
    data() {
        return {
            errorCode: '',
            firstDay: format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'Y-MM-dd'),
            lastDay: format(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), 'Y-MM-dd'),
            dataFilter: {
                startDate: '',
                endDate: ''
            },
            loading: true,
            dataRangeType: [
                {
                    text: 'This Week',
                    value: 0
                },
                {
                    text: 'This Month',
                    value: 1
                },
                {
                    text: 'Custom Range',
                    value: 2
                }
            ],
            valueType: 1,
            dateRange: [],
            stats: {
                totalPractices: 0,
                lessThan12MToExpire: 0,
                selfAssessmentDue: {
                    count: 0,
                    list: []
                },
                actionPlanDue: {
                    count: 0,
                    list: []
                },
                renewalDue: [],
                preferredDaysDue: [],
                upcomingVisits: {
                    count: 0,
                    list: []
                }
            }
        }
    },
    mounted() { 
        this.dataFilter = {
            startDate: this.firstDay,
            endDate: this.lastDay
        }
    },
    methods: {
        loadStats() {
            this.loading = true
            this.$http({
                method: 'POST',
                url: `/reports/management/overview/`,
                data: {
                    date_start: this.dataFilter.startDate,
                    date_end: this.dataFilter.endDate
                }})
            .then(({data}) => {
                this.stats = camelcaseKeys(data, {deep: true})
                this.errorCode = ''
            })
            .catch(({ response }) => {
                if(response?.status === 403){
                    this.$router.push({ path: '/Dashboard' })
                }
                if(response?.status !== 403 || response?.status !== 200) {
                    this.errorCode = 500
                    this.loading = false
                }
            })
            .finally(() => {
                this.loading = false
            })
        }
    },
    watch: {
        dataFilter() {
            this.loadStats()
        },
        valueType(value) {
            if (value === 0) {
                this.dataFilter = {
                    startDate: format(new Date().setDate(new Date().getDate() - new Date().getDay()), 'Y-MM-dd'),
                    endDate: format(new Date().setDate(new Date().getDate() - new Date().getDay() + 7), 'Y-MM-dd')
                }
            }
            else if (value === 1) {
                this.dataFilter = {
                    startDate: this.dataFilter.startDate = this.firstDay,
                    endDate: this.dataFilter.endDate = this.lastDay
                }
            }
        },
        dateRange(value) {
            if (value.length === 2) {
                this.dataFilter = {
                    startDate: value?.[0] ? value?.[0] : null,
                    endDate: value?.[1] ? value?.[1] : null
                }
            }
        }
    }
}
</script>