<template>
    <div>
        <div class="flex items-center justify-between my-4">
            <div class="flex items-center justify-space-between">
                <div class="flex items-center">
                    <SelfAsIcon class="text-primary-light w-10 mr-1" />
                    <h1 class="text-black text-xl lg:text-2xl">Self Assessments Due</h1>
                </div>
            </div>
            <!-- <h1 class="text-black text-lg lg:text-2xl">Comparative Report</h1> -->
        </div>  
        <div class="c-alert__table editable_table flex flex-col w-full">
            <v-data-table
                ref="filterTable"
                single-select
                item-key="id"
                :items-per-page="5"
                :item-class="() => 'cursor-pointer'"
                :headers="headers"
                :items="stats.selfAssessmentDue.list"
                :footer-props="footerProps"
                class="a-table w-full"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :no-data-text="emptyText"
                :no-results-text="emptyText"
                @click:row="rowClickHandler"
            >
            <template v-slot:[`item.selfAssessmentDue`]="{ item }">
                {{ formatDate(item.selfAssessmentDue) }}
            </template>
            <template v-slot:[`item.progress`]="{ item }">
                <v-progress-linear
                    background-color="#F0F0F0"
                    :color="getProgressColor((item.numCompletedIndicators/item.numTotalIndicators)*100)"
                    :value="(item.numCompletedIndicators/item.numTotalIndicators)*100"
                    rounded
                    height="6"
                ></v-progress-linear>
            </template>
            <template v-slot:[`item.redirect`]="{ item }">
                <div
                    class="text-right tablet-hide"
                    :class="{'active-tr': item.active}"
                >
                    <a
                        href="#"
                        @click.prevent="rowClickHandler(item)"
                        class="hover:text-primary mx-2"
                    >
                        <fa-icon icon="chevron-right" class="text-grey-2 ml-3" />
                    </a>
                </div>
            </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import SelfAsIcon from '@/assets/icons/icons_selfassessment.svg'
import {format, parseISO} from 'date-fns'

export default {
    name: 'SelfAssessmentsDue',
    components: { SelfAsIcon },
    data() {
        return {
            emptyText: 'Not found',
            headers: [
                { text: 'Due', align: 'left', value: 'selfAssessmentDue', class: 'w-150 count--header' },
                { text: 'Practice', align: 'left', value: 'practiceName', class: 'w-250 count--header', sortable: false },
                { text: 'Progress', align: 'left', value: 'progress', class: 'w-100 count--header', sortable: false },
                { text: '', align: 'left', value: 'redirect', fixed: true, sortable: false},
            ],
            footerProps: {
                'items-per-page-text':'',
                'items-per-page-options': [5, 10, 20, 30, 40],
                showFirstLastPage: true
            },
            sortBy: 'selfAssessmentDue',
            sortDesc: false,
            progress: 0
        }
    },
    props: {
        stats: {
            type: Object,
            default: () => {
            }
        }
    },
    methods: {
        formatDate(date) {
            if(!date) return ''
            return format(new Date(date),'dd/MM/yy')
        },
        getProgressColor(progress) {
            if (0 <= progress && progress <= 25) {
                return '#E80729'
            }
            else if (25 < progress && progress <= 50) {
                return '#dca200'
            }
            else if (50 < progress && progress <= 75) {
                return '#4EBBFF'
            }
            else if (75 < progress && progress <= 100) {
                return '#29AC64'
            }
            else {
                return ''
            }
        },
        rowClickHandler(obj, e) {
            this.$router
                .push({
                    name: 'Assessment', 
                    params: {id: obj.practiceId, assessmentId: obj.assessmentId.toString()}
                })
                .catch((err) => err)
                
            e.select(true)
        },     
    },
}
</script>