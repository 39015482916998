<template>
    <div>
        <v-menu
            v-model="fromDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            class="w-full"
            max-width="290px"
            min-width="290px"
            ref="dateRangePickerRef"
            :attach="attach"
        >
            <template v-slot:activator="{ on }">
                <div class="flex items-center">
                    <v-text-field
                        class="text-grey-2 leading-normal"
                        :label="label"
                        readonly
                        :solo="solo"
                        :flat="flat"
                        hide-details
                        dense
                        :value="fromDateDisplay"
                        v-on="on"
                    ></v-text-field>
                </div>
            </template>
            <v-date-picker
                locale="en-in"
                :min="minDate"
                color="primary"
                :max="maxDate"
                v-model="dateRangeValue"
                no-title
                scrollable
                range
                @input="setPickerValue"
            >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="fromDateMenu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="saveDateRange(dateRangeValue)">OK</v-btn>
            </v-date-picker>
        </v-menu>
    </div>
</template>

<script>
import { format } from 'date-fns'
import { computed, onMounted, ref, watch } from '@vue/composition-api'

export default {
    name: "DateRangePicker",
    props: {
        value: {
            type: Array
        },
        minDate: {
            type: String,
            default: () => '2019-12-01'
        },
        maxDate: {
            type: String,
            default: () => format(new Date(), 'yyyy-MM-dd')
        },
        outlined: {
            type: Boolean,
            default: false
        },
        solo: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            // default: 'From Date'
            default: ''
        },
        attach: {
            type: String,
            default: ''
        }
    },
    emits: ['input', 'change'],
    setup(props, { emit }) {
        const dateRangePickerRef = ref({})
        const dateRangeValue = ref([])
        const fromDateMenu = ref(false)
        const fromDateDisplay = computed(() => {
            if (!props.value || !props.value[0] || !props.value[1]) return ''
            return `${format(new Date(props.value[0]), 'dd/MM/yy')} to ${format(new Date(props.value[1]), 'dd/MM/yy')}`
        })

        const setPickerValue = (value) => {
            emit('input', value)
            emit('change', value)
        }

        const saveDateRange = (dateRangeValue) => {
            if (dateRangePickerRef.value?.save) dateRangePickerRef.value.save(dateRangeValue)
        }

        watch(dateRangeValue, (value) => {
            if (value) {
                emit('input', [...value])
                emit('change', value)
            }
        })

        onMounted(() => {
            dateRangeValue.value = [...props.value]
        })

        return { fromDateDisplay, dateRangeValue, fromDateMenu, dateRangePickerRef, saveDateRange, setPickerValue }
    }
}
</script>
