import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VMenu,{ref:"dateRangePickerRef",staticClass:"w-full",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px","attach":_vm.attach},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"flex items-center"},[_c(VTextField,_vm._g({staticClass:"text-grey-2 leading-normal",attrs:{"label":_vm.label,"readonly":"","solo":_vm.solo,"flat":_vm.flat,"hide-details":"","dense":"","value":_vm.fromDateDisplay}},on))],1)]}}]),model:{value:(_vm.fromDateMenu),callback:function ($$v) {_vm.fromDateMenu=$$v},expression:"fromDateMenu"}},[_c(VDatePicker,{attrs:{"locale":"en-in","min":_vm.minDate,"color":"primary","max":_vm.maxDate,"no-title":"","scrollable":"","range":""},on:{"input":_vm.setPickerValue},model:{value:(_vm.dateRangeValue),callback:function ($$v) {_vm.dateRangeValue=$$v},expression:"dateRangeValue"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.fromDateMenu = false}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.saveDateRange(_vm.dateRangeValue)}}},[_vm._v("OK")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }