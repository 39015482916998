<template>
    <div>
        <div class="flex items-center justify-between my-4">
            <div class="flex items-center justify-space-between">
                <div class="flex items-center">
                    <PracticesIcon class="text-primary-light w-10 mr-1" />
                    <h1 class="text-black text-xl lg:text-2xl">Overview</h1>
                </div>
            </div>
            <!-- <h1 class="text-black text-lg lg:text-2xl">Comparative Report</h1> -->
        </div>
        <div class="grid grid-cols-2 gap-x-4 gap-y-8">
            <div>
                <div>
                    <span
                        class="font-bold text-lg text-black mr-1"
                    >{{ stats.totalPractices.count }}</span>
                </div>
                <router-link
                    :to="{name: 'PracticeList'}"
                    class="col-span-1 rounded cursor-pointer flex items-center justify-between"
                >
                    <div class="flex items-center">
                        <span
                            class="text-grey-2 uppercase font-bold text-xs font-display mr-1"
                        >Total Practices</span> <fa-icon icon="chevron-right" class="text-xs text-grey-2"></fa-icon>
                    </div>
                </router-link>
            </div>
            <div>
                <div>
                    <span
                        class="font-bold text-lg text-black mr-1 text-light-blue"
                    >{{ stats.lessThan12MToExpire.count }}</span>
                </div>
                <router-link
                    :to="{name: 'PracticeList', query: { lessExpire: true }}"
                    class="col-span-1 rounded cursor-pointer flex items-center justify-between"
                >
                    <div class="flex items-center">
                        <span
                            class="text-grey-2 uppercase font-bold text-xs font-display mr-1"
                        >Less than 12M to expire</span> <fa-icon icon="chevron-right" class="text-xs text-grey-2"></fa-icon>
                    </div>
                </router-link>
            </div>

            <div>
                <div>
                    <span
                        class="font-bold text-lg text-black mr-1 text-yellow"
                    >{{ stats.selfAssessmentDue.count }}</span>
                </div>
                <router-link
                    :to="{name: 'PracticeList', query: { selfAssessmentDue: true }}"
                    class="col-span-1 rounded cursor-pointer flex items-center justify-between"
                >
                    <div class="flex items-center">
                        <span
                            class="text-grey-2 uppercase font-bold text-xs font-display mr-1"
                        >Self Assessment due</span> <fa-icon icon="chevron-right" class="text-xs text-grey-2"></fa-icon>
                    </div>
                </router-link>
            </div>

            <div>
                <div>
                    <span
                        class="font-bold text-lg text-black mr-1 text-red"
                    >{{ stats.actionPlanDue.count }}</span>
                </div>
                <router-link
                    :to="{name: 'PracticeList', query: { actionRequired: true }}"
                    class="col-span-1 rounded cursor-pointer flex items-center justify-between"
                >
                    <div class="flex items-center">
                        <span
                            class="text-grey-2 uppercase font-bold text-xs font-display mr-1"
                        >Practices require action</span> <fa-icon icon="chevron-right" class="text-xs text-grey-2"></fa-icon>
                    </div>
                </router-link>
            </div>

            <div>
                <div>
                    <span
                        class="font-bold text-lg text-black mr-1 text-red"
                    >{{ stats.renewalDue.count }}</span>
                </div>
                <router-link
                    :to="{name: 'PracticeList', query: { renewalDue: true }}"
                    class="col-span-1 rounded cursor-pointer flex items-center justify-between"
                >
                    <div class="flex items-center">
                        <span
                            class="text-grey-2 uppercase font-bold text-xs font-display mr-1"
                        >Renewal due</span> <fa-icon icon="chevron-right" class="text-xs text-grey-2"></fa-icon>
                    </div>
                </router-link>
            </div>

            <div>
                <div>
                    <span
                        class="font-bold text-lg text-black mr-1 text-red"
                    >{{ stats.preferredDaysDue.count }}</span>

                </div>
                <router-link
                    :to="{name: 'PracticeList', query: { preferenceDaysDue: true }}"
                    class="col-span-1 rounded cursor-pointer flex items-center justify-between"
                >
                    <div class="flex items-center">
                        <span
                            class="text-grey-2 uppercase font-bold text-xs font-display mr-1"
                        >Preferred days due</span> <fa-icon icon="chevron-right" class="text-xs text-grey-2"></fa-icon>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import PracticesIcon from '@/assets/icons/nav/practices.svg'

export default {
    name: 'OverviewManagement',
    components: { PracticesIcon },
    props: {
        stats: {
            type: Object,
            default: () => {
            }
        }
    }
}
</script>
