<template>
    <div>
        <div class="flex items-center justify-between my-4">
            <div class="flex items-center justify-space-between">
                <div class="flex items-center">
                    <SurveyIcon class="text-primary-light w-10 mr-1" />
                    <h1 class="text-black text-xl lg:text-2xl">Upcoming Survey Visits</h1>
                </div>
            </div>
            <!-- <h1 class="text-black text-lg lg:text-2xl">Comparative Report</h1> -->
        </div>  
        <div class="c-alert__table editable_table flex flex-col w-full">
            <v-data-table
                single-select
                :headers="headers"
                :items="stats.upcomingVisits.list"
                :items-per-page="5"
                :item-class="() => 'cursor-pointer'"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :footer-props="footerProps"
                :no-data-text="emptyText"
                :no-results-text="emptyText"
                class="a-table w-full border-none"
                @click:row="rowClickHandler"
            >
             <template v-slot:[`item.date`]="{ item }">
                {{ formatDate(item.date) }}
            </template>
            <template v-slot:[`item.redirect`]="{ item }">
                <div
                    class="text-right tablet-hide"
                    :class="{'active-tr': item.active}"
                >
                    <a
                        href="#"
                        @click.prevent="rowClickHandler(item)"
                        class="hover:text-primary mx-2"
                    >
                        <fa-icon icon="chevron-right" class="text-grey-2 ml-3" />
                    </a>
                </div>
            </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import SurveyIcon from '@/assets/icons/icons_survey.svg'
import {format} from 'date-fns'

export default {
    name: 'UpcomingSurveyVisits',
    components: { SurveyIcon,},
    props: {
        loading: {
            type: Boolean,
            default: true
        },
        stats: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {    
            emptyText: 'Not found',
            headers: [
                { text: 'Date', align: 'left', value: 'date', class: 'w-150 count--header' },
                { text: 'Practice', align: 'left', value: 'practiceName', class: 'w-250 count--header' },
                { text: '', align: 'left', value: 'redirect', fixed: true, sortable: false},
            ],
            footerProps: {
                'items-per-page-text':'',
                'items-per-page-options': [5, 10, 20, 30, 40],
                showFirstLastPage: true
            },
            sortBy: 'date',
            sortDesc: false,
        }
    },
    methods: {
        formatDate(date) {
            if(!date) return ''
            return format(new Date(date),'dd/MM/yy')
        },
        rowClickHandler(obj, e) {
            this.$router
                .push({
                    name: 'Staff', 
                    params: {id: obj.practiceId}
                })
                .catch((err) => err)
                
            e.select(true)
        },     
    }
}
</script>